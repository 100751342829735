import { useCallback, useState } from "react";
import "./modulosGeneralesCss.css";
import { useCookies } from 'react-cookie';
import axios from "axios";
import MenuDerechoEstados from "../general/MenuDerechoEstados";
export default function ModuloActualizacionStro1({ denuncia, carga3, estadosValores, estadosCodigos }) {
    const [opacity, setOpacity] = useState(0)
    const [cookies] = useCookies(["user"], ['asignado']);
    const [selectedOption, setSelectedOption] = useState(denuncia.empleadoAsignado);
    const [selectedOption2, setSelectedOption2] = useState(denuncia.asignado2);
    const [valorEstado1, setValorEstado1] = useState(denuncia.estadoNuevo);
    const [valorEstado2, setValorEstado2] = useState(denuncia.estado2);
    const [fechaRec, setFechaRec] = useState(denuncia.recepcionRecChoques)
    const [sicobeOption, setSicobeOption] = useState(denuncia.cbtSicobe);
    const [valoresContractuales, setValoresContractuales] = useState({ monto: denuncia.monto, comercial: denuncia.comercial, porcentaje: denuncia.porcentaje, aceptacion: denuncia.aceptacion, ordenDePago: denuncia.ordenDePago })
    const [responsabilidad, setResponsabilidad] = useState(denuncia.catStro)
    const [descStro, setDescStro] = useState(denuncia.descStro)
    const [observaciones, setObservaciones] = useState('')
    const [prioridad, setPrioridad] = useState(denuncia.prioridad)
    const [vencimiento, setVencimiento] = useState(denuncia.fechaVencimiento)
    // Funcion Transformar Fecha
    const today = new Date();
    let day = today.getDate();
    let month = today.getMonth() + 1;
    const year = today.getFullYear();
    day = (day < 10) ? '0' + day : day;
    month = (month < 10) ? '0' + month : month;
    const formattedDate = day + '/' + month + '/' + year;
    function getCurrentTime() {
        const now = new Date();
        let hour = now.getHours();
        let minute = now.getMinutes();
        minute = (minute < 10) ? '0' + minute : minute;
        return hour + ':' + minute;
    }
    const currentTime = getCurrentTime();
    // Array de estados y asignados 
    const optionsCarga = [
        { value: "JuanG", label: "JuanG", key: 1 },
        { value: "LucaP", label: "LucaP", key: 2 },
        { value: "StellaM", label: "StellaM", key: 3 },
        { value: "DanielaM", label: "DanielaM", key: 4 },
    ];
    const optionsChoque = [
        { value: "JuanG", label: "JuanG", key: 1 },
        { value: "LucaP", label: "LucaP", key: 2 },
        { value: "DanielaM", label: "DanielaM", key: 3 },
        { value: "DiegoD", label: "DiegoD", key: 4 },
        { value: "DiegoS", label: "DiegoS", key: 5 },
        { value: "OrelyC", label: "OrelyC", key: 6 },
        { value: "StellaM", label: "StellaM", key: 7 },
        { value: "LucianaF", label: "LucianaF", key: 8 },
        { value: "CandelaS", label: "CandelaS", key: 9 },
        { value: "MagaliG", label: "MagaliG", key: 10 },
        { value: "PilarS", label: "PilarS", key: 11 },
        { value: 'Maurom', label: 'Maurom', key: 12 },
        { value: 'ClaudiaF', label: 'ClaudiaF', key: 13 }
    ];
    const optionsContractuales = [
        { value: "JuanG", label: "JuanG", key: 1 },
        { value: "LucaP", label: "LucaP", key: 2 },
        { value: "DanielaM", label: "DanielaM", key: 3 },
        { value: "DiegoD", label: "DiegoD", key: 4 },
        { value: "DiegoS", label: "DiegoS", key: 5 },
        { value: "OrelyC", label: "OrelyC", key: 6 },
        { value: "StellaM", label: "StellaM", key: 7 },
        { value: "LucianaF", label: "LucianaF", key: 8 },
        { value: "CandelaS", label: "CandelaS", key: 9 },
        { value: "MagaliG", label: "MagaliG", key: 10 },
        { value: "PilarS", label: "PilarS", key: 11 },
        { value: "Augusto", label: "Augusto", key: 12 },
        { value: "PaulaC", label: "PaulaC", key: 13 },
    ];
    // Fin
    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const handleChange2 = (event) => {
        setSelectedOption2(event.target.value);
    };
    //
    const handleChangeEstado = (event) => {
        setValorEstado1(event.target.value);
    };
    const handleChangeEstado2 = (event) => {
        setValorEstado2(event.target.value);
    };
    const estadoSeleccionado = estadosValores?.todosEstados?.find(e => e.id === Number(valorEstado1));
    const estadoSeleccionado2 = estadosValores?.todosEstados?.find(e => e.id === Number(valorEstado2));
    const optionsPrioridad = [{ label: 'NINGUNA', value: 0 }, { label: 'BAJA', value: 1 }, { label: 'MEDIA', value: 2 }, { label: 'ALTA', value: 3 }]
    const prioridadSeleccionado = optionsPrioridad.find(p => p.value === Number(prioridad));
    // 
    const cbtSicobe = [
        { value: 1, label: "CON COBERTURA", key: 1 },
        { value: 2, label: "EN REVISION", key: 2 },
        { value: 3, label: "CON COBERTURA ANALIZADO POR COBRANZAS", key: 3 },
        { value: 4, label: "SIN COBERTURA ANALIZADO POR COBRANZAS", key: 4 },
        { value: 5, label: "PARA IMPUTAR", key: 5 },
    ]
    const handleChangeSicobe = (event) => {
        setSicobeOption(event.target.value);
    };
    //
    const handleChangePrioridad = (event) => {
        setPrioridad(event.target.value);
    };
    //

    const actualizarValores = useCallback(async (e) => {
        e.preventDefault();
        setOpacity(1);
        // Función modular para actualizar valores
        const checkAndUpdate = async (condition, updateAction, logDescription, additionalActions = []) => {
            if (!condition) return;
            await updateAction();
            if (logDescription) {
                await axios.post(`https://back.agsseguros.online/api/cronologia`, {
                    id: denuncia.id,
                    desc: logDescription,
                    obs: observaciones,
                    jerarquia: cookies.user.depto === "cargas" ? "CRG" : "DEN",
                    motivo: "STRO",
                    autor: cookies.user.usuario,
                    fecha: formattedDate,
                    hora: currentTime,
                });
            }
            for (const action of additionalActions) {
                await action();
            }
        };
        await checkAndUpdate(
            observaciones !== '',
            () =>
                axios.post(`https://back.agsseguros.online/api/actualizarDescStro`, {
                    id: denuncia.id,
                    desc: descStro,
                }),
            `Observacion`
        );
        // 1. Actualizar descripción
        await checkAndUpdate(
            descStro !== denuncia.descStro,
            () =>
                axios.post(`https://back.agsseguros.online/api/actualizarDescStro`, {
                    id: denuncia.id,
                    desc: descStro,
                }),
            `El usuario ${cookies.user.usuario} actualizó la descripción del stro.`
        );
        // 2. Cambiar asignado1 y enviar correo
        await checkAndUpdate(
            selectedOption !== denuncia.empleadoAsignado,
            () =>
                axios.post(`https://back.agsseguros.online/api/actualizarAsignadoStro`, {
                    id: denuncia.id,
                    asignado: selectedOption,
                }),
            `El usuario ${cookies.user.usuario} asignó el stro a ${selectedOption}`,
            [
                () =>
                    axios.post(`https://back.agsseguros.online/api/mail/mailAsignado`, {
                        id: denuncia.id,
                        user: selectedOption,
                        nroStro: denuncia.nroStro,
                        entryId: denuncia.entryId,
                    }),
            ]
        );
        // 3. Cambiar asignado2 y enviar correo
        await checkAndUpdate(
            denuncia.catStro?.length === 6 && selectedOption2 !== denuncia.asignado2,
            () =>
                axios.post(`https://back.agsseguros.online/api/actualizarAsignadoStro2`, {
                    id: denuncia.id,
                    asignado: selectedOption2,
                }),
            `El usuario ${cookies.user.usuario} asignó el stro a ${selectedOption2}`,
            [
                () =>
                    axios.post(`https://back.agsseguros.online/api/mail/mailAsignado`, {
                        id: denuncia.id,
                        user: selectedOption2,
                        nroStro: denuncia.nroStro,
                        entryId: denuncia.entryId,
                    }),
            ]
        );
        // 4. Cambiar estado1
        await checkAndUpdate(
            valorEstado1 !== Number(denuncia.estadoNuevo),
            () =>
                axios.post(`https://back.agsseguros.online/api/actualizarEstadoStro`, {
                    id: denuncia.id,
                    estado: Number(valorEstado1),
                }),
            `El usuario ${cookies.user.usuario} cambió el estado a ${estadoSeleccionado.nombre}`
        );
        // 5. Cambiar estado2
        await checkAndUpdate(
            (denuncia.catStro?.length === 6 && valorEstado2 !== Number(denuncia.estado2)),
            () =>
                axios.post(`https://back.agsseguros.online/api/actualizarEstadoStro2`, {
                    id: denuncia.id,
                    estado: Number(valorEstado2),
                }),
            (denuncia.catStro?.length === 6 && valorEstado2 !== Number(denuncia.estado2)) ? `El usuario ${cookies.user.usuario} cambió el estado a ${estadoSeleccionado2.nombre}` : ''
        );
        // 6. Actualizar fecha de recepción
        await checkAndUpdate(
            fechaRec !== denuncia.recepcionRecChoques,
            () =>
                axios.post(`https://back.agsseguros.online/api/recepcionReChoques`, {
                    id: denuncia.id,
                    fecha: fechaRec,
                }),
            `Se recepcionó el reclamo con fecha ${fechaRec}`
        );
        // 7. Cambiar prioridad
        await checkAndUpdate(
            prioridad !== denuncia.prioridad,
            () =>
                axios.post(`https://back.agsseguros.online/api/actualizarPrioridadStro`, {
                    stro: denuncia.id,
                    prioridad: prioridad,
                }),
            `El usuario ${cookies.user.usuario} cambió la prioridad a ${prioridadSeleccionado.label}`
        );
        // 8. Cambiar fecha de vencimiento
        await checkAndUpdate(
            vencimiento !== denuncia.fechaVencimiento,
            () =>
                axios.post(`https://back.agsseguros.online/api/fechaVencimiento`, {
                    id: denuncia.id,
                    fechaVencimiento: vencimiento,
                }),
            `Se modificó la fecha de vencimiento a ${vencimiento}`
        );
        // 9. Cambiar CBT Sicobe
        await checkAndUpdate(
            (sicobeOption !== denuncia.cbtSicobe),
            () =>
                axios.post(`https://back.agsseguros.online/api/actualizarSicobe`, {
                    id: denuncia.id,
                    sicobe: sicobeOption,
                }),
            sicobeOption ? `El usuario ${cookies.user.usuario} actualizó el valor de sicobe a ${cbtSicobe[sicobeOption - 1].label}` : null
        );
        // 10. Cambiar responsabilidad
        await checkAndUpdate(
            responsabilidad !== denuncia.catStro,
            () =>
                axios.post(`https://back.agsseguros.online/api/actualizarResponsabilidad`, {
                    id: denuncia.id,
                    responsabilidad: responsabilidad,
                }),
            `El usuario ${cookies.user.usuario} actualizó la responsabilidad a ${responsabilidad}`
        );
        // 11. Actualizar valores contractuales
        await checkAndUpdate(
            valoresContractuales.monto !== denuncia.monto ||
            valoresContractuales.comercial !== denuncia.comercial ||
            valoresContractuales.porcentaje !== denuncia.porcentaje ||
            valoresContractuales.aceptacion !== denuncia.aceptacion ||
            valoresContractuales.ordenDePago !== denuncia.ordenDePago,
            () =>
                axios.post(`https://back.agsseguros.online/api/contractuales/actualizarValoresContractuales`, {
                    id: denuncia.id,
                    ...valoresContractuales,
                }),
            `El usuario ${cookies.user.usuario} actualizó valores contractuales: ${Object.entries(valoresContractuales)
                .map(([key, value]) => `${key}: ${value}`)
                .join(", ")}`
        );
        // Recargar la página
        window.location.reload();
    }, [denuncia, cookies, formattedDate, currentTime, descStro, selectedOption, selectedOption2, valorEstado1, valorEstado2, fechaRec, prioridad, vencimiento, sicobeOption, responsabilidad, valoresContractuales, observaciones]);

    const [botonOff, setBotonOff] = useState(0)
    return (
        <>
            <section className="moduloActualizacionContainerNuevo" style={{ opacity: opacity === 1 ? 0.25 : 1 }}>
                {opacity === 1 ? <div className="SpinnerInTheMiddle"><div class="spinner-border text-success center" role="status"><span class="visually-hidden center">Loading...</span></div></div> : <></>}
                <div className="rowModuloActualizacion2">
                    <div className="colModuloActualizacion">
                        <label htmlFor="" className="labelStro">Codigo/s</label>
                        <input type="text" value={responsabilidad} className="inputModuloActualizacion" onChange={(e) => setResponsabilidad(e.target.value)} disabled={cookies?.user?.depto === 'emision' ? true : false} />
                    </div>
                    {carga3 === true ?
                        <div className="colModuloActualizacion">
                            <label htmlFor="" className="labelStro">{`Codigo ${denuncia?.catStro?.toString().substring(0, 3) || '1'}`}</label>
                            <div className="selectEstadoDentroStro d-flex align-items-center text-white" style={{
                                backgroundColor: estadosValores?.todosEstados?.find(e => e.id === Number(valorEstado1))?.hexa || 'silver'
                            }}>
                                {cookies.user.id === 107 ?
                                    <select value={valorEstado1} onChange={handleChangeEstado} className="select" disabled={cookies?.user?.depto === 'emision' ? true : false}>
                                        <option value="302">ENVIADO A COTIZAR</option>
                                        <option value="311">COTIZADO</option>
                                    </select>
                                    :
                                    <select value={valorEstado1} onChange={handleChangeEstado} className="select" disabled={cookies?.user?.depto === 'emision' ? true : false}>
                                        <option value="" disabled>Estado</option>
                                        {estadosCodigos?.codigo1?.estados?.map((option) => (<option key={option.id} value={option.id}>{option.nombre}</option>))}
                                        <option value="201">CARGADO</option>
                                        <option value="203">CERRADO</option>
                                        <option value="200">SIN LEER</option>
                                    </select>}
                            </div>
                        </div> :
                        <div className="colModuloActualizacion center">
                            <div class="spinner-border text-success" role="status"></div>
                        </div>
                    }
                    <div className="colModuloActualizacion">
                        <label htmlFor="" className="labelStro">Asignado</label>
                        <div className="selectAsignadoDentroStro d-flex align-items-center text-white">
                            <select value={selectedOption} onChange={handleChange} className="select" disabled={cookies?.user?.depto === 'emision' ? true : false}>
                                <option value="">Asignar A</option>
                                {cookies.user.depto === 'cargas' ? optionsCarga.map((option) => (<option key={option.key} value={option.value}>{option.label}</option>)) : (cookies.user.depto === 'choques' ? optionsChoque.map((option) => (<option key={option.key} value={option.value}>{option.label}</option>)) : (cookies.user.depto === 'contractuales' ? optionsContractuales.map((option) => (<option key={option.key} value={option.value}>{option.label}</option>)) : <></>))}
                            </select>
                        </div>
                    </div>
                    {carga3 === true ?
                        <div className="colModuloActualizacion">
                            {denuncia?.catStro?.length === 6 ?
                                <>
                                    <label htmlFor="" className="labelStro">{`Codigo ${denuncia.catStro.toString().substring(3, 6)}`}</label>
                                    <div className="selectEstadoDentroStro d-flex align-items-center text-white" style={{ backgroundColor: `${estadosValores?.todosEstados?.find(e => e.id === Number(valorEstado2))?.hexa}` }}>
                                        {cookies.user.id === 107 ?
                                            <select value={valorEstado1} onChange={handleChangeEstado} className="select" disabled={cookies?.user?.depto === 'emision' ? true : false}>
                                                <option value="302">ENVIADO A COTIZAR</option>
                                                <option value="311">COTIZADO</option>
                                            </select>
                                            :
                                            <select value={valorEstado1} onChange={handleChangeEstado} className="select" disabled={cookies?.user?.depto === 'emision' ? true : false}>
                                                <option value="" disabled>Estado</option>
                                                {estadosCodigos?.codigo1?.estados?.map((option) => (<option key={option.id} value={option.id}>{option.nombre}</option>))}
                                                <option value="201">CARGADO</option>
                                                <option value="203">CERRADO</option>
                                                <option value="200">SIN LEER</option>
                                            </select>}
                                    </div>
                                </> : <></>}
                        </div> :
                        <div className="colModuloActualizacion center">
                            <div class="spinner-border text-success " role="status"></div>
                        </div>
                    }
                    <div className="colModuloActualizacion">
                        {denuncia?.catStro && denuncia.catStro.length === 6 ?
                            <>
                                <label htmlFor="" className="labelStro">Asignado 2</label>
                                <div className="selectAsignadoDentroStro2 d-flex align-items-center text-white">
                                    <select value={selectedOption2} onChange={handleChange2} className="select" disabled={cookies?.user?.depto === 'emision' ? true : false}>
                                        <option value="">Asignar A</option>
                                        {cookies.user.depto === 'cargas' ? optionsCarga.map((option) => (<option key={option.key} value={option.value}>{option.label}</option>)) : (cookies.user.depto === 'choques' ? optionsChoque.map((option) => (<option key={option.key} value={option.value}>{option.label}</option>)) : (cookies.user.depto === 'contractuales' ? optionsContractuales.map((option) => (<option key={option.key} value={option.value}>{option.label}</option>)) : <></>))}
                                    </select>
                                </div>
                            </> : <></>}
                    </div>
                </div>
                <div className="d-flex align-items-center mt-2">
                    <div className="w-100">
                        <label htmlFor="" className="labelStro">Descripcion Stro</label>
                        <textarea className="inputModuloActualizacionDesc" onChange={(e) => setDescStro(e.target.value)} value={descStro} />
                    </div>
                </div>
                <div className="rowModuloActualizacion mt-2">
                    <div className="colModuloActualizacion">
                        <label htmlFor="" className="labelStro">Cbt Sicobe</label>
                        <div className="selectSicobeDentroStro">
                            <select value={sicobeOption} onChange={handleChangeSicobe} className="select2A" disabled={cookies?.user?.depto === 'emision' ? true : false}>
                                <option value=""></option>
                                {cbtSicobe.map((option) => (<option key={option.key} value={option.value}>{option.label}</option>))}
                            </select>
                        </div>
                    </div>
                    <div className="colModuloActualizacion">
                        <label htmlFor="" className="labelStro">{`F. Rec Recibido`}</label>
                        <input type="date" value={fechaRec} onChange={(e) => setFechaRec(e.target.value)} className="inputModuloActualizacion" disabled={cookies?.user?.depto === 'emision' ? true : false} />
                    </div>
                    <div className="colModuloActualizacion">
                        <label htmlFor="" className="labelStro">{`Prioridad`}</label>
                        <div className="selectSicobeDentroStro">
                            <select value={prioridad} onChange={handleChangePrioridad} className="select2A" disabled={cookies?.user?.depto === 'emision' ? true : false}>
                                <option value=""></option>
                                {optionsPrioridad.map((option) => (<option key={option.key} value={option.value}>{option.label}</option>))}
                            </select>
                        </div>
                    </div>
                    <div className="colModuloActualizacion">
                        <label htmlFor="" className="labelStro">{`Vencimiento`}</label>
                        <input type="date" value={vencimiento} onChange={(e) => setVencimiento(e.target.value)} className="inputModuloActualizacion" disabled={cookies?.user?.depto === 'emision' ? true : false} />
                    </div>
                    <div className="colModuloActualizacion">
                        <label htmlFor="" className="labelStro">Nivel</label>
                    </div>
                </div>
                <div className="rowModuloActualizacion mt-2">
                    <div className="colModuloActualizacion">
                        <label htmlFor="" className="labelStro">Monto</label>
                        <input type="number" disabled={cookies?.user?.depto === 'emision' ? true : false} value={valoresContractuales.monto} className="inputModuloActualizacion" onChange={(e) => setValoresContractuales((prevState) => ({ ...prevState, monto: e.target.value }))} />
                    </div>
                    <div className="colModuloActualizacion">
                        <label htmlFor="" className="labelStro">Comercial</label>
                        <input type="number" disabled={cookies?.user?.depto === 'emision' ? true : false} value={valoresContractuales.comercial} className="inputModuloActualizacion" onChange={(e) => setValoresContractuales((prevState) => ({ ...prevState, comercial: e.target.value }))} />
                    </div>
                    <div className="colModuloActualizacion">
                        <label htmlFor="" className="labelStro">Pocentaje</label>
                        <input type="number" disabled={cookies?.user?.depto === 'emision' ? true : false} value={valoresContractuales.porcentaje} className="inputModuloActualizacion" onChange={(e) => setValoresContractuales((prevState) => ({ ...prevState, porcentaje: e.target.value }))} />
                    </div>
                    <div className="colModuloActualizacion">
                        <label htmlFor="" className="labelStro">Aceptacion</label>
                        <input type="text" disabled={cookies?.user?.depto === 'emision' ? true : false} value={valoresContractuales.aceptacion} className="inputModuloActualizacion" onChange={(e) => setValoresContractuales((prevState) => ({ ...prevState, aceptacion: e.target.value }))} />
                    </div>
                    <div className="colModuloActualizacion">
                        <label htmlFor="" className="labelStro">Orden de Pago</label>
                        <input type="number" disabled={cookies?.user?.depto === 'emision' ? true : false} value={valoresContractuales.ordenDePago} className="inputModuloActualizacion" onChange={(e) => setValoresContractuales((prevState) => ({ ...prevState, ordenDePago: e.target.value }))} />
                    </div>
                </div>
                <div className="d-flex align-items-center mt-2">
                    <div className="w-100 me-2">
                        <label htmlFor="" className="labelStro">Observaciones</label>
                        <input type="text" className="inputModuloActualizacionDesc" disabled={cookies?.user?.depto === 'emision' ? true : false} onChange={(e) => setObservaciones(e.target.value)} />
                    </div>
                    <div className="">
                        <button className="btnActualizarModuloActualizacion text-white" disabled={cookies?.user?.depto === 'emision' ? true : false} onClick={(e) => actualizarValores(e)}>ACTUALIZAR</button>
                        <button className="btnActualizarModuloActualizacion mt-1 text-white" onClick={(e) => setBotonOff(1)}>Ver Todo</button>
                    </div>
                </div>
            </section >
            {botonOff !== 0 ? <MenuDerechoEstados botonOff={botonOff} setBotonOff={setBotonOff} denuncia={denuncia} /> : <></>
            }
        </>
    );
}
