import Frente from "../carga/Frente";
import Sicobe from "../carga/Sicobe";
import TablaOtrasDenuncias from "../carga/TablaOtrasDenuncias";
import Endosos from "../carga/Endosos";
import { useEffect, useRef } from "react";
export default function MenuIzquierdo({ botonOff, setBotonOff, denuncia }) {
    const elementRef = useRef(null); 
    const handleClickOutside = (event) => {
        if (elementRef.current && !elementRef.current.contains(event.target)) {
            setBotonOff(0);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <div className='offCanvas shadow pe-3 ps-3 pt-3' ref={elementRef}>
            <div className='d-flex justify-content-end'><button onClick={(e) => setBotonOff(0)} className='btnNO btnSs'><i class="bi bi-x"></i></button></div>
            {botonOff === 1 ? <><Frente denuncia={denuncia} /></>
                : (botonOff === 2 ? <><Sicobe/></>
                : (botonOff === 3 ?<><TablaOtrasDenuncias denuncia={denuncia} /></>
                :(botonOff === 4 ?<><Endosos endosos={denuncia.endosos} /></>
                : <></>)))}
        </div>
    )
}